import React, { useState } from "react";
import { Layout } from "../components/utilityComponents/layout";
import { graphql, StaticQuery } from "gatsby";
import { NewsHeader } from "../components/newsPageComponents/newsHeader";
import { Card } from "../components/utilityComponents/layoutComponents/news";
import { Container } from "../helpers/container";
import leftBlue from "../assets/images/leftBlue.svg";
import rightBlue from "../assets/images/rightBlue.svg";

export default function AktualnosciPage() {
  const [search, setSearch] = useState("");
  const [amountPost, setAmountPost] = useState<number>();
  const [page, setPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(6);
  const [order, setOrder] = useState("newest");
  return (
    <StaticQuery
      query={graphql`
        {
          allStrapiPost {
            nodes {
              slug
              title
              shortDescription
              createdAt
              img {
                url
              }
            }
          }
        }
      `}
      render={({ allStrapiPost: { nodes } }) => {
        setAmountPost(nodes.length);
        console.log(order);
        return (
          <Layout
            darkBackground
            headerChildren={
              <NewsHeader
                order={order}
                setOrder={setOrder}
                search={search}
                setSearch={setSearch}
              />
            }
          >
            <Container className="newsPageContainer">
              <div className="newsPageContent">
                {nodes
                  .filter(({ title }: any) =>
                    title.toUpperCase().includes(search.toUpperCase())
                  )
                  .sort((a, b) => {
                    switch (order) {
                      case "za":
                        if (a.title > b.title) {
                          return -1;
                        }
                        if (b.title > a.title) {
                          return 1;
                        }
                        return 0;
                        break;
                      case "az":
                        if (a.title < b.title) {
                          return -1;
                        }
                        if (b.title < a.title) {
                          return 1;
                        }
                        return 0;
                        break;
                      case "olderst":
                        const _a = new Date(a.createdAt);
                        const _b = new Date(b.createdAt);
                        if (_a > _b) {
                          return -1;
                        }
                        if (_b > _a) {
                          return 1;
                        }
                        return 0;
                        break;
                      case "newest":
                        const __a = new Date(a.createdAt);
                        const __b = new Date(b.createdAt);
                        if (__a < __b) {
                          return -1;
                        }
                        if (__b < __a) {
                          return 1;
                        }
                        return 0;
                        break;
                    }
                  })
                  .slice(
                    0 + postPerPage * (page - 1),
                    postPerPage + postPerPage * (page - 1)
                  )
                  .map((properties: any) => {
                    return <Card {...properties} />;
                  })}
              </div>
              <hr className="newsPageContainer__hr" />
              <div className="newsPageContent__bottom">
                <label>
                  <select
                    className="newsPageContent__bottom--select"
                    onChange={({ target: { value } }) =>
                      setPostPerPage(Number(value))
                    }
                    value={postPerPage}
                  >
                    <option value="6">6</option>
                    <option value="8">8</option>
                    <option value="12">12</option>
                  </select>
                  wyników na stronie
                </label>
                <label className="newsPageContent__bottom--input">
                  <img
                    src={leftBlue}
                    onClick={() => setPage(Number(page - 1))}
                  />
                  <input
                    type="number"
                    value={page}
                    onChange={({ target: { value } }) => setPage(Number(value))}
                  />
                  <span style={{width:"35px",textAlign:'center'}}> z {Math.ceil(amountPost! / postPerPage)}</span>
                  <img
                    onClick={() => setPage(Number(page + 1))}
                    src={rightBlue}
                  />
                </label>
              </div>
            </Container>
          </Layout>
        );
      }}
    />
  );
}
