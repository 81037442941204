import React from "react";
import searchIcon from "./../../assets/images/search.svg";
import {useWidth} from "../../hooks/useWidth";

export const NewsHeader = ({ order, setOrder, search, setSearch }: any) => {
    const {isDesktop} = useWidth()
  return (
    <>
      <h1 className="news__header">Aktualności</h1>
      <div
        className="form__row full__width news__header__row"
        style={{ width:isDesktop? "608px":'100%' }}
      >
        <div className="input" style={{ width:isDesktop?"288px": '100%',marginRight: isDesktop ? "32px" : 0}}>
          <select
              style={{height:'92%'}}
              value={order}
            onChange={({ target: { value } }) => {
              setOrder(value)
              console.log(value)
            }}
          >
            <option value="newest">Data: od najnowszych</option>
            <option value="olderst">Data: od najstarszych</option>
            <option value="az">Nazwa: A-Z</option>
            <option value="za">Nazwa: Z-A</option>
          </select>
        </div>
        <div className="input" style={{ width:isDesktop?"288px": '100%'}}>
          <img className="input__search" src={searchIcon} />
          <input
            style={{height:'92%'}}
            placeholder="Szukaj"
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
          />
        </div>
      </div>
    </>
  );
};
